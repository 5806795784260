<template>
    <div>
        {{ value_text }}
        <div>
            <a v-if="value"
                :href="value"
                target="_blank"
                title="Visor de mapas">
                <img
                    class="btn-icon"
                    src="/static/img/btn/localizacion.png" />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Location',
    props: {
        value: String,
        value_text: String,
        img_size: String,
        icon_color: String
    },
    data: () => ({
        frontend_path: process.env.VUE_APP_FRONTEND_PATH
    })
}
</script>

<style scoped>
div {
    display: inline-block;
}
img {
    vertical-align: middle;
}
</style>