<template>

    <v-data-table
        :headers="headers"
        :items="items"
        :page.sync="page"
        :items-per-page="options.itemsPerPage"
        :search="search"
        :custom-filter="customSearch"
        :loading="loading"
        sort-by="publicinformations"
        :sort-desc="true"
        dense
        item-class="data-item"
        :caption="page_title"
        :style="'--caption-bgcolor:' + caption_bgcolor"
    >

        <!-- documentación -->
        <template #item.documentation_type="{ item }">
            <a target="_blank" :href="data_folder_path + item.documentation_path">{{ item.documentation_type }}</a>
        </template>

        <!-- publicación en boletín -->
        <template #item.publicinformations="{ value }">
            <PublicInformations
                :data_folder_path="data_folder_path"
                :value="value">
            </PublicInformations>
        </template>

        <!-- descarga sin firma -->
        <template #item.unsigned_documentation="{ item }">
            <UnsignedDownload
                :data_url="data_url"
                :data_folder_path="data_folder_path"
                :item="item"
                :caption_bgcolor="caption_bgcolor">
            </UnsignedDownload>
        </template>

        <!-- descarga con firma -->
        <template #item.signed_documentation="{ item }">
            <SignedDownload
                :data_url="data_url"
                :data_folder_path="data_folder_path"
                :item="item"
                :caption_bgcolor="caption_bgcolor">
            </SignedDownload>
        </template>

        <!-- expediente administrativo -->
        <template #item.administrative_exp_documentation="{ item }">
            <AdministrativeExpedientDownload
                :data_url="data_url"
                :data_folder_path="data_folder_path"
                :item="item"
                :caption_bgcolor="caption_bgcolor">
            </AdministrativeExpedientDownload>
        </template>

    </v-data-table>

</template>

<script>
    import { eventBus } from '../main'
    import {
        trackEvent,
        customSearch,
        getData, getItems, apiCall,
        publicInformationsSort
    } from '../modules/common'
    import PublicInformations from './BasePublicInformations'
    import UnsignedDownload from './BaseUnsignedDownload'
    import SignedDownload from './BaseSignedDownload'
    import AdministrativeExpedientDownload from './BaseAdministrativeExpedient'

    export default {
        name: 'OPI',
        props: {
            data_url: String,
            data_folder_path: String,
        },
        components: {
            PublicInformations,
            UnsignedDownload,
            SignedDownload,
            AdministrativeExpedientDownload
        },
        data: function() {
            return {
                page_title: 'Ordenanza Provisional Insular (OPI)',
                options: {},
                items: [],
                totalItems: 0,
                loading: true,
                search: '',
                page: 1,
                caption_bgcolor: 'rgb(92,151,186)',
                headers: [
                    {
                        text: 'Referencia',
                        value: 'reference',
                        sortable: true
                    },
                    {
                        text: 'Instrumento',
                        value: 'instrument',
                        sortable: false
                    },
                    {
                        text: 'Denominación',
                        value: 'denomination',
                        sortable: true
                    },
                    {
                        text: 'Tramitación',
                        value: 'processing_status',
                        sortable: false
                    },
                    {
                        text: 'Órgano',
                        value: 'organization',
                        sortable: true
                    },
                    {
                        text: 'Fecha',
                        value: 'resolution_date',
                        sortable: true,
                        align: 'center',
                        width: 185
                    },
                    {
                        // text: 'Publicación en boletín',
                        text: 'Publicación',
                        value: 'publicinformations',
                        sortable: true,
                        align: 'center',
                        width: 195,
                        sort: publicInformationsSort
                    },
                    {
                        // text: 'Descarga de plan sin firma electrónica',
                        text: 'Descarga sin firma',
                        value: 'unsigned_documentation',
                        sortable: false,
                        align: 'center'
                    },
                    {
                        // text: 'Descarga de plan con firma electrónica',
                        text: 'Descarga con firma',
                        value: 'signed_documentation',
                        sortable: false,
                        align: 'center'
                    },
                    {
                        // text: 'Expediente administrativo',
                        text: 'Expediente',
                        value: 'administrative_exp_documentation',
                        sortable: false,
                        align: 'center'
                    }
                ],
            }
        },
        created: function() {
            eventBus.$on('search_change', (data) => {
                this.search = data;
            })
        },
        mounted: function() {
            document.title = this.page_title
            this.getData()
        },
        methods: {
            trackEvent: trackEvent,
            customSearch: customSearch,
            getData: getData,
            getItems: getItems,
            apiCall: apiCall
        }
    }
</script>

<style scoped>
/* v-data-table */
.v-data-table::v-deep caption {
  background-color: var(--caption-bgcolor);
}
</style>