var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title-history",style:('--subtitle-bgcolor: ' + _vm.subtitle_bgcolor)},[_vm._v(_vm._s(_vm.history_title))]),_c('v-data-table',{attrs:{"id":"history-table","headers":_vm.history_headers,"items":_vm.history_items,"items-per-page":-1,"hide-default-footer":"","search":_vm.search,"custom-filter":_vm.customSearch,"loading":_vm.loading,"sort-by":"publicinformations","sort-desc":true,"dense":"","item-class":"data-item","must-sort":"","header-props":{sortIcon: 'mdi-arrow-up-thick'}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.code_ext}},[_vm._v(_vm._s(item.code))])]}},{key:"item.processing_status",fn:function(ref){
var item = ref.item;
return [_c('Location',{attrs:{"img_size":'15px',"value":item.viewer_url,"value_text":item.processing_status,"icon_color":_vm.captionBgColor}})]}},{key:"item.browsable_document_path",fn:function(ref){
var item = ref.item;
return [_c('BrowsableDocument',{attrs:{"data_folder_path":_vm.dataFolderPath,"value":item.browsable_document_path,"pending":item.browsable_document_pending,"icon_color":_vm.captionBgColor}})]}},{key:"item.unsigned_documentation",fn:function(ref){
var item = ref.item;
return [_c('UnsignedDownload',{attrs:{"route_name":'Download_unsigned_enp',"data_url":_vm.dataUrl,"data_folder_path":_vm.dataFolderPath,"item":item,"caption_bgcolor":_vm.subtitle_bgcolor}})]}},{key:"item.signed_documentation",fn:function(ref){
var item = ref.item;
return [_c('SignedDownload',{attrs:{"route_name":'Download_signed_enp',"data_url":_vm.dataUrl,"data_folder_path":_vm.dataFolderPath,"item":item,"caption_bgcolor":_vm.subtitle_bgcolor}})]}},{key:"item.administrative_exp_documentation",fn:function(ref){
var item = ref.item;
return [_c('AdministrativeExpedientDownload',{attrs:{"route_name":'Download_admexp_enp',"data_url":_vm.dataUrl,"data_folder_path":_vm.dataFolderPath,"item":item,"caption_bgcolor":_vm.subtitle_bgcolor}})]}},{key:"item.publicinformations",fn:function(ref){
var value = ref.value;
return [_c('PublicInformations',{attrs:{"data_folder_path":_vm.dataFolderPath,"value":value}})]}}])}),_c('v-divider'),(_vm.detail_items.length)?_c('div',[_c('div',{staticClass:"title-details"},[_vm._v(_vm._s(_vm.detail_title))]),_c('v-divider'),_c('v-data-table',{attrs:{"id":"detail-table","headers":_vm.detail_headers,"items":_vm.detail_items,"items-per-page":-1,"hide-default-footer":"","hide-default-header":"","search":_vm.search,"custom-filter":_vm.customSearch,"loading":_vm.loading,"sort-by":"last_procedure_date","sort-desc":true,"dense":"","item-class":"data-item"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('DetailHistory',{attrs:{"detail_id":item.id,"data_url":_vm.dataUrl,"data_folder_path":_vm.dataFolderPath,"page_title":item.detail_type + '. ' + item.denomination,"caption_bgcolor":_vm.captionBgColor}})]}}],null,false,3791968936)})],1):_vm._e(),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }