var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{style:('--caption-bgcolor:' + _vm.caption_bgcolor),attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.page,"items-per-page":_vm.options.itemsPerPage,"search":_vm.search,"custom-filter":_vm.customSearch,"loading":_vm.loading,"sort-by":"publicinformations","sort-desc":true,"dense":"","item-class":"data-item","caption":_vm.page_title},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.documentation_type",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":_vm.data_folder_path + item.documentation_path}},[_vm._v(_vm._s(item.documentation_type))])]}},{key:"item.publicinformations",fn:function(ref){
var value = ref.value;
return [_c('PublicInformations',{attrs:{"data_folder_path":_vm.data_folder_path,"value":value}})]}},{key:"item.unsigned_documentation",fn:function(ref){
var item = ref.item;
return [_c('UnsignedDownload',{attrs:{"data_url":_vm.data_url,"data_folder_path":_vm.data_folder_path,"item":item,"caption_bgcolor":_vm.caption_bgcolor}})]}},{key:"item.signed_documentation",fn:function(ref){
var item = ref.item;
return [_c('SignedDownload',{attrs:{"data_url":_vm.data_url,"data_folder_path":_vm.data_folder_path,"item":item,"caption_bgcolor":_vm.caption_bgcolor}})]}},{key:"item.administrative_exp_documentation",fn:function(ref){
var item = ref.item;
return [_c('AdministrativeExpedientDownload',{attrs:{"data_url":_vm.data_url,"data_folder_path":_vm.data_folder_path,"item":item,"caption_bgcolor":_vm.caption_bgcolor}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }