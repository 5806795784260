import Vue from 'vue'
import Vuex from 'vuex'

import pnenp_history from './pnenp/history'
import pnenp_detail from './pnenp/detail'

import territorial_history from './territorial/history'
import territorial_detail from './territorial/detail'

import pio_history from './pio/history'
import pio_detail from './pio/detail'

import decpubsocint_history from './decpubsocint/history'

import porn_history from './porn/history'
import porn_detail from './porn/detail'

import pii_main from './pii/main'
import pii_history from './pii/history'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    territorial_history, territorial_detail,
    pio_history, pio_detail,
    decpubsocint_history,
    pnenp_history, pnenp_detail,
    porn_history, porn_detail,
    pii_main, pii_history,
  },
  strict: debug,
})