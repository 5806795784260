<template>

    <div>

        <div class="title-history" :style="'--subtitle-bgcolor: ' + subtitle_bgcolor">{{ history_title }}</div>

        <!--
            Historial de trámites (alteración principal)
        -->
        <v-data-table
            id="history-table"
            :headers="history_headers"
            :items="history_items"
            :items-per-page="-1"
            hide-default-footer
            :search="search"
            :custom-filter="customSearch"
            :loading="loading"
            sort-by="publicinformations"
            :sort-desc="true"
            dense
            item-class="data-item"
            must-sort
            :header-props="{sortIcon: 'mdi-arrow-up-thick'}"
        >

            <!-- referencia -->
            <template v-slot:item.code="{ item }">
                <span :title="item.code_ext">{{ item.code }}</span>
            </template>

            <!-- tramitación -->
            <template v-slot:item.processing_status="{ item }">
                <Location
                    :img_size="'15px'"
                    :value="item.viewer_url"
                    :value_text="item.processing_status"
                    :icon_color="captionBgColor">
                </Location>
            </template>

            <!-- documento navegable -->
            <template #item.browsable_document_path="{ item }">
                <BrowsableDocument
                    :data_folder_path="dataFolderPath"
                    :value="item.browsable_document_path"
                    :pending="item.browsable_document_pending"
                    :icon_color="captionBgColor">
                </BrowsableDocument>
            </template>

            <!-- descarga sin firma -->
            <template #item.unsigned_documentation="{ item }">
                <UnsignedDownload
                    :route_name="'Download_unsigned_enp'"
                    :data_url="dataUrl"
                    :data_folder_path="dataFolderPath"
                    :item="item"
                    :caption_bgcolor="subtitle_bgcolor">
                </UnsignedDownload>
            </template>

            <!-- descarga con firma -->
            <template #item.signed_documentation="{ item }">
                <SignedDownload
                    :route_name="'Download_signed_enp'"
                    :data_url="dataUrl"
                    :data_folder_path="dataFolderPath"
                    :item="item"
                    :caption_bgcolor="subtitle_bgcolor">
                </SignedDownload>
            </template>

            <!-- expediente administrativo -->
            <template #item.administrative_exp_documentation="{ item }">
                <AdministrativeExpedientDownload
                    :route_name="'Download_admexp_enp'"
                    :data_url="dataUrl"
                    :data_folder_path="dataFolderPath"
                    :item="item"
                    :caption_bgcolor="subtitle_bgcolor">
                </AdministrativeExpedientDownload>
            </template>

            <!-- publicación en boletín -->
            <template #item.publicinformations="{ value }">
                <PublicInformations
                    :data_folder_path="dataFolderPath"
                    :value="value">
                </PublicInformations>
            </template>

        </v-data-table>

        <!-- borde inferior última fila -->
        <v-divider></v-divider>

        <div v-if="detail_items.length">

            <div class="title-details">{{ detail_title }}</div>

            <v-divider></v-divider>

            <!--
                Alteraciones del plan (excepto la principal)
            -->
            <v-data-table
                id="detail-table"
                :headers="detail_headers"
                :items="detail_items"
                :items-per-page="-1"
                hide-default-footer
                hide-default-header
                :search="search"
                :custom-filter="customSearch"
                :loading="loading"
                sort-by="last_procedure_date"
                :sort-desc="true"
                dense
                item-class="data-item"
            >

                <!-- historial -->
                <template v-slot:item.id="{ item }">
                    <DetailHistory
                        :detail_id="item.id"
                        :data_url="dataUrl"
                        :data_folder_path="dataFolderPath"
                        :page_title="item.detail_type + '. ' + item.denomination"
                        :caption_bgcolor="captionBgColor"
                    ></DetailHistory>
                </template>
            </v-data-table>
        </div>

        <!-- borde inferior última fila -->
        <v-divider></v-divider>

    </div>

</template>

<script>
    import { eventBus } from '../main'
    import {
        customSearch,
        getMainHistoryData, getDetailData,
        apiCall, getItems,
        publicInformationsSort
    } from '../modules/common'
    import BrowsableDocument from './BaseBrowsableDocument'
    import UnsignedDownload from './BaseUnsignedDownload'
    import SignedDownload from './BaseSignedDownload'
    import AdministrativeExpedientDownload from './BaseAdministrativeExpedient'
    import PublicInformations from './BasePublicInformations'
    import Location from './BaseLocation'
    import DetailHistory from './PNENPENPDetailHistory'

    export default {
        components: {
            BrowsableDocument,
            UnsignedDownload,
            SignedDownload,
            AdministrativeExpedientDownload,
            PublicInformations,
            Location,
            DetailHistory
        },
        name: 'PNENPENPHistory',
        props: {
            reference: String,
            page_title: String,
            data_url: String,
            data_folder_path: String,
            caption_bgcolor: String
        },
        data: () => ({
            options: {},
            history_title: '',
            history_items: [],
            history_total_items: 0,
            detail_title: 'Alteraciones del instrumento',
            detail_items: [],
            detail_total_items: 0,
            loading: true,
            search: '',
            history_headers: [
                {
                    text: 'Referencia',
                    value: 'code'
                },
                {
                    text: 'Tramitación',
                    value: 'processing_status',
                    sortable: true
                },
                {
                    text: 'Documento navegable optimizado',
                    value: 'browsable_document_path',
                    sortable: false,
                    align: 'center',
                    width: 215
                },
                {
                    text: 'Descarga sin firma',
                    value: 'unsigned_documentation',
                    sortable: false,
                    align: 'center',
                    width: 130
                },
                {
                    text: 'Descarga con firma',
                    value: 'signed_documentation',
                    sortable: false,
                    align: 'center',
                    width: 135
                },
                {
                    text: 'Expediente',
                    value: 'administrative_exp_documentation',
                    sortable: false,
                    align: 'center',
                    width: 100
                },
                {
                    text: 'Publicación',
                    value: 'publicinformations',
                    sortable: true,
                    align: 'center',
                    width: 210,
                    sort: publicInformationsSort
                },
            ],
            detail_headers: [
                {
                    text: 'Historial de trámites',
                    value: 'id',
                    align: 'center'
                }
            ],
            subtitle_bgcolor: '#d9d9d9'
        }),
        created: function() {
            eventBus.$on('search_change', (data) => {
                this.search = data;
            })
        },
        updated: function() {
            // history_title
            if (this.history_items.length > 0) {
                this.history_title = this.ref + '. '
                    + this.history_items[0].instrument + '. '
                    + this.history_items[0].denomination
                document.title = this.history_title
            }
        },
        mounted: function() {
            if (typeof this.reference !== 'undefined') {
                this.$store.commit('pnenp_history/setReference', this.reference)
                this.$store.commit('pnenp_history/setPageTitle', this.page_title)
                this.$store.commit('pnenp_history/setDataUrl', this.data_url)
                this.$store.commit('pnenp_history/setDataFolderPath', this.data_folder_path)
                this.$store.commit('pnenp_history/setCaptionBgColor', this.caption_bgcolor)
            }
            this.getMainHistoryData(this.dataUrl, this.ref)
            this.getDetailData(this.dataUrl, this.ref)
        },
        computed: {
            dataUrl: function() {
                return this.data_url || this.$store.state.pnenp_history.data_url
            },
            dataFolderPath: function() {
                return this.data_folder_path || this.$store.state.pnenp_history.data_folder_path
            },
            ref: function() {
                return this.reference || this.$store.state.pnenp_history.reference
            },
            captionBgColor: function() {
                return this.caption_bgcolor || this.$store.state.pnenp_history.caption_bgcolor
            }
        },
        methods: {
            customSearch: customSearch,
            getMainHistoryData: getMainHistoryData,
            getDetailData: getDetailData,
            apiCall: apiCall,
            getItems: getItems
        }
    }
</script>

<style scoped>
.title-history {
    background-color: var(--subtitle-bgcolor);
    font-size: 0.875rem;
    line-height: 1.4rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 2rem;
}

.title-details {
    color: #000;
    /* simulamos negrita */
    text-shadow: 0.01rem 0.01rem #000;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    min-height: 1.2rem;
    line-height: 0.9em;
    margin-top: 3rem;
    padding-left: 16px;
}

/* eliminamos padding de las celdas de la tabla de detalle */
#detail-table::v-deep > .v-data-table__wrapper > table > tbody > tr > td,
#detail-table::v-deep > .v-data-table__wrapper > table > tbody > tr > th,
#detail-table::v-deep > .v-data-table__wrapper > table > thead > tr > td,
#detail-table::v-deep > .v-data-table__wrapper > table > thead > tr > th,
#detail-table::v-deep > .v-data-table__wrapper > table > tfoot > tr > td,
#detail-table::v-deep > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0;
}

/* th v-data-table*/
>>> .v-data-table__wrapper>table>thead>tr>th {
    background-color: #1E6AA8 !important;
    color: #fff !important;
}

/* nota de cautela */
#div-caution-note {
    /* display: none; */

    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    margin: 2px;
    font-size: 0.7rem;
    color: #fff;
    opacity: 50%;
    background-color: rgba(0, 0, 0, 0.1);
}
</style>